"use client";

import { useTranslations } from "next-intl";
import Link from "next/link";
import { FaPhone, FaWhatsapp } from "react-icons/fa";

const LandingHeroBanner = () => {
  const t = useTranslations("landing_hero");
  return (
    <div className="w-full h-full relative">
      <video
        className="relative h-[100vh]  md:h-[800px] lg:h-screen w-full object-cover object-center"
        src="/assets/videos/hero_video.mp4"
        autoPlay
        loop
        preload="auto"
        playsInline
        muted
        controls={false}
        poster="/assets/images/slide_2.jpeg"
      ></video>
      <div className="absolute top-1/2 md:top-[70%] left-1/2 -translate-x-1/2 w-full z-50">
        {/* <div className="relative flex items-center justify-center gap-4">
          <Link
            href={`tel:+966920003455`}
            target="_blank"
            className="bg-white w-14 h-14 flex items-center justify-center rounded-full"
          >
            <FaPhone className="text-[#5a558e] w-8 h-8" />
          </Link>
          <div
            onClick={() => whatsAppLink("+966920003455")}
            className="bg-white w-14 h-14 flex items-center justify-center rounded-full relative"
          >
            <Image
              src="/assets/icons/whatsapp-color-icon.svg"
              alt="whatsapp chat icon"
              width={32}
              height={32}
            />
          </div>
        </div> */}
        <div className="hidden relative md:flex items-center justify-center gap-4">
          <Link
            href={`tel:+966920003455`}
            target="_blank"
            className="bg-white px-6 py-3 flex items-center justify-center gap-3 rounded-lg"
          >
            <FaPhone className="text-[#5a558e] w-5 h-5" />
            <span className="font-medium">{t("call")}</span>
          </Link>
          <Link
            href={`https://wa.me/+966920003455`}
            target="_blank"
            className="bg-[#25D366] text-white px-6 py-3 flex items-center justify-center gap-3 rounded-lg"
          >
            <FaWhatsapp className="text-white w-5 h-5" />
            <span className="font-medium">{t("whatsapp")}</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LandingHeroBanner;

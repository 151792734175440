import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_bab1841f/components/AboutBlock/AboutBlock.tsx");
;
import(/* webpackMode: "eager" */ "/tmp/build_bab1841f/components/FeaturesBlock/FeaturesBlock.module.scss");
;
import(/* webpackMode: "eager" */ "/tmp/build_bab1841f/app/[locale]/Home.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_bab1841f/components/FlightSearchForm/FlightSearchForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_bab1841f/components/LandingHeroBanner/LandingHeroBanner.tsx");
;
import(/* webpackMode: "eager" */ "/tmp/build_bab1841f/node_modules/next/dist/client/image-component.js");
